<template>
  <div>
    <v-container fluid class="pa-8">
      <v-row>
        <v-col cols="12" sm="6" md="6" class="d-flex">
          <h3 class="name">USERS</h3>
        </v-col>
        <!-- Add User Dialog -->
        <v-select
          :menu-props="{ bottom: true, offsetY: true }"
          :items="status"
          v-model="defaultStatus"
          outlined
          dense
          hide-details
          class="me-2 header-status"
          @change="checkisActive()"
          color="indigo darken-1"
        ></v-select>
        <!-- Add User Dialog End -->
        <v-text-field
          append-icon="mdi-magnify"
          type="text"
          name="search"
          label="Search"
          outlined
          dense
          clearable
          hide-details
          v-model="filteredText"
          @click:clear="(filteredText = ''), getUsers()"
          @click:append="getUsers"
          color="indigo darken-1"
        ></v-text-field>
        <v-tooltip> </v-tooltip>
        <v-btn
          height="40"
          hide-details
          large
          class="mx-2 pa-0"
          dark
          color="indigo"
          @click="adduserdialoge = true"
        >
          <v-icon dark> mdi-plus </v-icon>
        </v-btn>
      </v-row>
      <div class="line"></div>
      <template>
        <v-data-table
          v-if="allUsers == null"
          :headers="headers"
          class="elevation-0"
          hide-default-footer
        >
        </v-data-table>
        <v-data-table
          v-else
          :headers="headers"
          :items="allUsers"
          :items-per-page="selectedPageSize"
          class="elevation-0 customheader"
          hide-default-footer
          :single-select="singleSelect"
          item-key="userId"
          v-model="selected"
          checkbox-color="indigo"
        >
          <template v-slot:[`item.fullName`]="{ item }">
            <v-badge
              v-if="item.role == 10"
              color="success darken-2"
              dot
              offset-x="-5"
            >
              {{ item.fullName }}
            </v-badge>
            <span v-else>{{ item.fullName }}</span>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  dense
                  small
                  color="primary"
                  @click="takeData(item), (editdialog = !editdialog)"
                  class="ms-2"
                  v-bind="attrs"
                  v-on="on"
                  >mdi-square-edit-outline</v-icon
                >
              </template>
              <span>Edit</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  dense
                  small
                  color="red darken-2"
                  @click="takeData(item), (deletDialog = true)"
                  class="ms-2"
                  v-bind="attrs"
                  v-on="on"
                  >mdi-trash-can-outline</v-icon
                >
              </template>
              <span>Delete</span>
            </v-tooltip>
          </template>
          <template v-slot:[`item.isActive`]="{ item }">
            <v-chip
              dark
              v-if="item.isActive"
              color="grey darken-2"
              x-small
              label
            >
              Active
            </v-chip>
            <span v-else> Inactive </span>
          </template>
          <template v-slot:[`item.role`]="{ item }">
            <v-chip
              dark
              v-if="item.role == 100"
              color="success darken-2"
              small
              label
            >
              Admin
            </v-chip>
            <span v-else> User </span>
          </template>
        </v-data-table>
        <template>
          <v-row class="no-gutters mt-2" align="center">
            <v-spacer style="width: 75%"></v-spacer>

            <span class="pagination-text">Rows per page</span>
            <v-select
              :menu-props="{ bottom: true, offsetY: true }"
              :items="options"
              v-model="selectedPageSize"
              dense
              class="ma-2"
              style="width: 50px"
              @change="getUsers"
              color="success darken-1"
            ></v-select>

            <span
              v-if="pagination != null"
              class="mr-4 grey--text pagination-text"
            >
              Page {{ pagination.page }} of {{ pagination.totalPages }}
            </span>
            <v-icon
              small
              dark
              color="indigo darken-1"
              class="mr-1"
              @click="backwardButtonCall()"
              >mdi-chevron-left</v-icon
            >

            <v-icon
              small
              dark
              color="indigo darken-1"
              @click="forwardButtonCall()"
              class="ml-1"
              >mdi-chevron-right</v-icon
            >
          </v-row>
        </template>

        <v-dialog v-model="adduserdialoge" persistent max-width="500px">
          <v-card>
            <v-toolbar color="#E3E3E3" class="indigo--text" elevation="0">
              <h3>Add User</h3>
            </v-toolbar>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="6" md="12">
                    <v-text-field
                      label="Full name*"
                      outlined
                      dense
                      v-model="userfullName"
                      hide-details
                      color="indigo darken-1"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="12">
                    <v-text-field
                      label="Email*"
                      outlined
                      dense
                      v-model="userEmail"
                      hide-details
                      color="indigo darken-1"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="userPassword"
                      label="Password*"
                      required
                      outlined
                      dense
                      hide-details
                      color="indigo darken-1"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="6" md="12">
                    <v-text-field
                      label="Phone"
                      outlined
                      dense
                      v-model="userPhone"
                      hide-details
                      color="indigo darken-1"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="12">
                    <v-select
                      :menu-props="{ bottom: true, offsetY: true }"
                      label="Status"
                      :items="userstatus"
                      v-model="userStatusType"
                      outlined
                      dense
                      hide-details
                      class=""
                      color="indigo darken-1"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="6" md="12">
                    <v-select
                      :menu-props="{ bottom: true, offsetY: true }"
                      label="Role"
                      :items="userrole"
                      v-model="userRole"
                      outlined
                      dense
                      hide-details
                      class=""
                      color="indigo darken-1"
                    ></v-select>
                  </v-col>
                </v-row>
              </v-container>
              <small>*indicates required field</small>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn class="grey--text" text @click="adduserdialoge = false">
                Close
              </v-btn>
              <v-btn
                dark
                class="dark"
                @click="addNewUser(), (adduserdialoge = false)"
              >
                Add User
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="editdialog" persistent max-width="500" class="pa-12">
          <v-card>
            <v-toolbar color="#E3E3E3" class="indigo--text" elevation="0">
              <p> {{ user.systemUuid  }}</p>
            </v-toolbar>
            <v-container>
              <v-row dense class="pa-0">
                <v-col dense cols="12" sm="6" md="12">
                  <v-text-field
                    v-model="user.fullName"
                    label="Full Name"
                    dense
                    outlined
                    flat
                  ></v-text-field>
                  <v-text-field
                    label="phone"
                    v-model="user.phone"
                    dense
                    outlined
                    flat
                  ></v-text-field>
                  <v-text-field
                    v-model="user.email"
                    label="Email"
                    dense
                    outlined
                    flat
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                class="grey--text"
                text
                @click="getUsers(), (editdialog = false)"
              >
                Cancel
              </v-btn>
              <v-btn
                dark
                class="dark"
                @click="UpdateUser(), (editdialog = false)"
              >
                Update
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- Delete Dialog -->
        <v-dialog v-model="deletDialog" persistent max-width="400">
          <v-card>
            <v-toolbar
              color="#E3E3E3"
              elevation="0"
              class="indigo--text text-h5"
            >
              Delete User
            </v-toolbar>
            <v-card-text class="mt-4"
              >Are You sure? You want to delete
              <b class="error--text text--darken-2">{{
                this.user.fullName
              }}</b></v-card-text
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn class="grey--text" text @click="deletDialog = false">
                Cancel
              </v-btn>
              <v-btn
                dark
                class="dark"
                @click="deleteUser(), (deletDialog = false)"
              >
                Delete
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
      <!-- Progressbar -->
      <v-progress-circular
        v-if="allUsers == null"
        :size="50"
        :width="3"
        color="black"
        indeterminate
      ></v-progress-circular>
      <!-- Alert  -->
      <v-alert
        border="left"
        dark
        color="red darken-2"
        width="300"
        :value="alert"
        >User already exists</v-alert
      >
    </v-container>
  </div>
</template>
<script>
import axios from "axios";
import config from "@/Configuration/config";
export default {
  name: "UserPage",
  data() {
    return {
      userPhone: null,
      userRole: 0,
      userStatusType: true,
      userEmail: null,
      userPassword: null,
      userfullName: null,
      adduserdialoge: false,
      inputText: "",
      alert: false,
      editdialog: false,
      loader: false,
      myImage: "",
      modelTitle: "",
      showpass: false,
      dialog: false,
      deletDialog: false,
      activateDialog: false,
      revokeDialog: false,
      singleSelect: false,
      selected: null,
      fullName: "",
      email: "",
      password: "",
      index: null,
      selectedUsersIds: [],
      defaultStatus: true,
      defaultrole: 30,
      defaultuserStatus: true,
      userrole: [
        { text: "Admin", value: 100 },
        { text: "User", value: 0 },
      ],
      userstatus: [
        { text: "Active", value: true },
        { text: "InActive", value: false },
      ],
      status: [
        { text: "Active", value: true },
        { text: "InActive", value: false },
      ],

      headers: [
        { text: "Full Name", value: "fullName" },
        { text: "Email", value: "email" },
        { text: "Phone", value: "phone" },
        { text: "Password", value: "password" },
        { text: "Role", value: "role" },
        { text: "Status", value: "isActive" },
        {
          text: "Actions",
          value: "actions",
          width: "100px",
          align: "center",
          sortable: false,
        },
      ],
      allUsers: null,
      user: [],
      rules: {
        required: (value) => !!value || "Required.",
        counter: (value) => value.length <= 20 || "Max 20 characters",
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
      },
      page: 1,
      options: [
        { text: "10", value: 10 },
        { text: "25", value: 25 },
        { text: "50", value: 50 },
        { text: "100", value: 100 },
      ],

      selectedPageSize: 10,
      pagination: null,
      pageNumOnSearch: "",
      filteredText: "",
      totalRecords: "",
    };
  },
  computed: {},
  methods: {
    getUsers() {
      this.allUsers = null;
      axios
        .post(config.apiBaseUrl + "/api/User/GetAll", {
          pageSize: this.selectedPageSize,
          page: this.page,
          filterText: this.filteredText,
          userId: this.userId,
        })
        .then((response) => {
          this.allUsers = response.data.data;
        })
        .catch((error) => {
          if (error.config.headers.Authorization == "Bearer null") {
            this.$router.push("/404");
          }
        });
    },

    checkisActive() {
      this.allUsers = null;
      axios
        .post(config.apiBaseUrl + "/api/User/GetAll", {
          pageSize: 25,
          page: 1,
          filterText: "",
          filterTag: "",
          isActive: this.defaultStatus,
        })
        .then((response) => {
          this.allUsers = response.data.data;
          console.log(response)
        })
        .catch((error) => {
          if (error.config.headers.Authorization == "Bearer null") {
            this.$router.push("/404");
          }
        });
    },

    deleteUser() {
      const fd = new FormData();
      fd.append("userId", this.user.userId);
      axios({
        method: "post",
        url: config.apiBaseUrl + "/api/User/Remove",
        data: fd,
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "applicatio/json",
        },
      }).then(() => {
        // this.allUsers = response.data.data;
        this.getUsers();
      });
    },

    getUsersStatus() {
      this.allUsers = null;
      axios
        .post(
          config.apiBaseUrl + "",
          {
            userId: 0,
            fullName: "string",
            signupDate: "2023-01-20T23:05:08.193Z",
            email: "string",
            phone: "string",
            password: "string",
            role: 100,
            lastOtpCode: "string",
            otpExpiry: "2023-01-20T23:05:08.193Z",
            isActive: true,
          },
          {
            statustype: this.userStatusType,
          }
        )
        .then((response) => {
          this.allUsers = response.data.data;
          this.getUsers();
        })
        .catch((error) => {
          if (error.config.headers.Authorization == "Bearer null") {
            this.$router.push("/404");
          }
        });
    },

    forwardButtonCall() {
      //it does not run on equal to condition
      if (this.page < this.pagination.totalPages) {
        this.page++;
        this.getUsers();
      } else {
        return;
      }
    },
    backwardButtonCall() {
      if (this.page > 1) {
        this.page--;
        this.getUsers();
      } else {
        return;
      }
    },
    addNewUser() {
      console.log(this.userRole);
      axios
        .post(
          config.apiBaseUrl + "/api/User/AddOrUpdate",
          {
            id: 0,
            fullName: this.userfullName,
            email: this.userEmail,
            phone: this.userPhone,
            password: this.userPassword,
            role: this.userRole,
            isActive: this.userStatusType,
          }
          // {
          //   userId: 0,
          //   fullName: this.userfullName,
          //   signupDate: "",
          //   email: this.userEmail,
          //   phone: this.userPhone,
          //   password: this.userPassword,
          //   role: this.userRole,
          //   lastOtpCode: "",
          //   otpExpiry: "",
          //   isActive: this.userStatusType,
          // }
        )
        .then(() => {
          this.$toast.success("user added successfully", {
            position: "top-center",
            timeout: 1041,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.79,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: false,
            icon: true,
            rtl: false,
          });
          this.getUsers();
        })
        .catch(() => {
          this.$toast.warning("something went wrong!", {
            position: "top-center",
            timeout: 1041,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.79,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: false,
            icon: true,
            rtl: false,
          });
        });
    },
    UpdateUser() {
      console.log("user added");
      console.log(this.user.userId);

      axios
        .post(config.apiBaseUrl + "/api/User/AddOrUpdate", {
          id: this.user.userId,
          systemUuid: "",
          product: 100,
          type: 10,
          isDesktop: true,
          isWeb: true,
          fullName: this.user.fullName,
          email: this.user.email,
          phone: this.user.phone,
          business: "",
          address: "",
          country: "",
          signupDate: "",
          issuanceDate: "",
          expiryDate: "",
          connectionString: "",
          key: "",
          allowedSystems: "",
          isActive: true,
        })
        .then(() => {
          this.$toast("User updated Successfully.", {
            position: "top-center",
            timeout: 2000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.79,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: false,
            icon: true,
            rtl: false,
          });
          this.selected = [];
          this.getUsers();
        });
    },

    clearForm() {
      this.user = [];
    },
    takeData(user) {
      this.user = user;
    },

    ActivateUser() {
      this.selectedUsersIds = [];
      this.selectedUsersIds.push(this.user.userId);
      axios
        .post(
          config.apiBaseUrl + "/api/User/ActivateUser",
          this.selectedUsersIds
        )
        .then((this.selected = []), this.getUsers());
    },
    RevokeUser() {
      const fd = new FormData();
      fd.append("userId", this.user.userId);
      axios({
        method: "post",
        url: config.apiBaseUrl + "/api/User/RevokeUser",
        data: fd,
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "applicatio/json",
        },
      }).then(this.getUsers);
    },
  },

  created() {
    this.getUsers();
  },
};
</script>
<style scoped>
.v-progress-circular {
  position: absolute !important;
  top: calc(50% - 25px);
  left: calc(50% - 25px);
}
.v-alert {
  position: absolute;
  top: 10px;
  right: 20px;
}
.header-status {
  width: 50px !important;
}
.pagination-text {
  font-size: 12px !important;
}
.menu-page {
  text-transform: inherit !important;
}
.customheader {
  font-size: 0px !important;
}
</style>
