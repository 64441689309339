<template>
  <div class="login-container">
    <v-container
      id="LoginTop"
      class="d-flex justify-center align-center text-center"
      fluid
    >
      <v-snackbar
        v-model="wrongMail"
        top
        :timeout="notificationTimeout"
        :color="notificationColor"
      >
        <span> {{ notificationText }} </span>
      </v-snackbar>
      <v-card class="pa-10 mx-auto elevation-8" width="500" height="auto">
        <img
          src="../assets/mobivone-mv.svg"
          width="100"
          alt="Logo"
          class="ma-auto mb-3"
          transition="scale-transition"
        />

        <span>
          <p class="text-start subtitle1 grey--text lighten-1">
            Enter the email address associated with your account and we will
            send you a link to reset your password.
          </p>
        </span>
        <v-text-field
          name="email"
          id="email"
          v-model="email"
          v-model.lazy="email"
          outlined
          label="Email Address"
          prepend-inner-icon="mdi-email"
          autocomplete="off"
          color="#2A579A"
          class="pt-3"
        ></v-text-field>
        <!-- :rules="[rules.required, rules.email]" -->

        <div>
          <v-btn
            height="50"
            class="mb-2"
            color="#2A579A"
            depressed
            dark
            block
            @click="forgotPassMethod(), (loader = true)"
            @keyup.enter="forgotPassMethod(), (loader = true)"
          >
            Continue
          </v-btn>
        </div>

        <span> Already have an account. <a href="/">Log-In</a> </span>
      </v-card>
    </v-container>

    <v-progress-circular
      v-if="loader"
      :size="50"
      :width="3"
      color="#2A579A"
      indeterminate
    ></v-progress-circular>
  </div>
</template>
  
  <script>
import axios from "axios";
import config from "@/Configuration/config";
export default {
  name: "LoginPage",

  data: () => ({
    wrongMail: false,
    notificationColor: "red",
    notificationText: "Invalid Email Address",
    notificationTimeout: 2000,
    logedinuser: null,
    isLoggedin: false,
    pageSize: 25,
    page: 1,
    filterText: null,
    filterTag: null,
    isActive: true,
    type: 10,
    title: "Preliminary report",
    email: "",
    initialValue: "",
    showpass: false,
    password: "",
    loader: null,
    loading: false,
    rules: {
      required: (value) => !!value || "Required.",
      min: (v) => v.length >= 8 || "Min 8 characters",
      emailMatch: () => `The email and password you entered don't match`,
    },
  }),
  methods: {
    async forgotPassMethod() {
      const fd = new FormData();
      fd.append("email", this.email);

      axios({
        method: "post",
        url: config.apiBaseUrl + "/api/Auth/ForgotPassword",
        data: fd,
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "applicatio/json",
        },
      }).then((res) => {
        // alert(res.data)
        this.loader = false;
        if (res.data.isSuccessful == true) {
          this.wrongMail = true;
          this.notificationColor = "green";
          this.notificationText = "Sended Successfully";
          this.$router.push("/");
        } else {
          this.wrongMail = true;
          this.notificationColor = "red";
        }
      });
    },
  },
};
</script>
  <style scoped>
* {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  transition: background-color 5000s ease-in-out 0s;
}
.v-progress-circular {
  position: absolute;
  top: calc(50% - 100px);
  left: calc(50% - 35px);
}
.v-application a {
  margin-left: 0px;
  text-decoration: none;
  text-transform: initial;
  font-weight: 500;
  font-size: 13px;
}
.backgroundColor {
  height: 100vh;
  background-color: #f5f7f8 !important;
}
#LoginTop {
  margin-top: 80px;
}

@media only screen and (max-width: 480px) {
  #LoginTop {
    margin-top: 100px;
  }
}
@media screen and (min-width: 1800px) {
  #LoginTop {
    margin-top: 150px;
  }
}
</style>
  