import Vue from 'vue'
import Vuex from 'vuex'


Vue.use(Vuex);


export default new Vuex.Store({
  state: {
    isLoggedin: false,
    UserRole: 0,
    user: null,
    token: null,
    role: null,
    userId: null,
    userInfo: null,

  },
  getters: {
   
  },
  mutations: {
    
  },
  actions: {
   
  },
  modules: {
  }
})
