<template>
  <div id="app">
    <v-app>
      <HeaderComp v-if="this.$store.state.isLoggedin"  />
      <v-main>
        <router-view />
      </v-main>
    </v-app>
  </div>
</template>

<script>
import HeaderComp from "./components/HeaderComp.vue";
export default {
  name: "App",
  data() {
    return {
      isLoggedin: false,
      hello: true,
    };
  },
  components: { HeaderComp },
  methods: {
    isLogged() {
      this.$store.state.LoggedIn = localStorage.getItem("isLoggedin");
    },
  },
  beforeCreate(){
    this.$store.state.LoggedIn = localStorage.getItem("isLoggedin");
  },
  created() {
    this.isLogged();
  },
};
</script>
<style>

@import "./assets/style.css";
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  overflow: none;
}
.v-application a {
  margin-left: 20px;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 20px;
}
</style>
