import Vue from 'vue'
import VueRouter from 'vue-router'
import LicensesComp from '../components/LicensesComp.vue'
import UserPage from "../components/UserPage"
import LoginPage from "../components/LoginPage"
import ForgotPassward from "../components/ForgotPassword"
import SignupPage from '../components/SignupPage'
import PageNotFound from '../components/PageNotFound'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'LoginPage',
    component: LoginPage
  },
  {
    path: '/signup',
    name: 'SignupPage',
    component: SignupPage
  },
  {
    path: '/ForgotPassward',
    name: 'ForgotPassward',
    component: ForgotPassward
  },
  {
    path: '/login',
    name: 'LoginPage',
    component: LoginPage
  },
  {
    path: '/users',
    name: 'UserPage',
    component: UserPage
  },
  {
    path: '/Licenses',
    name: 'LicensesComp',
    component: LicensesComp
  },

  {
    path: '/404',
    name: 'PageNotFound',
    component: PageNotFound
  },
  {
    path: "*/",
    name: 'redirectPageNotFound',
    redirect: '/404'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router