<template>
  <div class="login-container">
    <v-container
      id="LoginTop"
      class="d-flex justify-center align-center text-center"
      fluid
    >
      <v-card class="pa-10 mx-auto login-wrapper" width="500" height="auto">
        <img
          src="../assets/mobivone-mv.svg"
          width="100"
          alt="Logo"
          class="ma-auto mb-3"
          transition="scale-transition"
        />

        <v-text-field
          name="email"
          id="email"
          v-model="email"
          v-model.lazy="email"
          outlined
          label="Username"
          prepend-inner-icon="mdi-email"
          autocomplete="off"
          color="#2A579A"
        ></v-text-field>
        <!-- :rules="[rules.required, rules.email]" -->

        <v-text-field
          id="password"
          name="password"
          label="Password"
          outlined
          v-model="password"
          :append-icon="showpass ? 'mdi-eye' : 'mdi-eye-off'"
          :type="showpass ? 'text' : 'password'"
          prepend-inner-icon="mdi-lock"
          counter
          autocomplete="off"
          color="#2A579A"
          @click:append="showpass = !showpass"
          @keyup.enter="loginMethod"
        ></v-text-field>
        <a href="/ForgotPassward" class="d-flex justify-start mb-3">
          Forgot Password
        </a>

        <div>
          <v-btn
            height="50"
            depressed
            dark
            block
            @click="loginMethod(), (loader = true)"
            :style="{
              backgroundColor: password && email ? '#2A579A' : '#CECECE',
              color: password && email ? '#ffffff' : '#000000',
            }"
          >
            Login
          </v-btn>
        </div>
      </v-card>
    </v-container>

    <v-progress-circular
      v-if="loader"
      :size="50"
      :width="3"
      color="#2A579A"
      indeterminate
    ></v-progress-circular>
  </div>
</template>

<script>
import axios from "axios";
import config from "@/Configuration/config";
export default {
  name: "LoginPage",

  data: () => ({
    logedinuser: null,
    isLoggedin: false,
    pageSize: 25,
    page: 1,
    filterText: null,
    filterTag: null,
    isActive: true,
    type: 10,
    title: "Preliminary report",
    email: "",
    dialog: false,
    initialValue: "",
    showpass: false,
    password: "",
    loader: null,
    loading: false,
    rules: {
      required: (value) => !!value || "Required.",
      min: (v) => v.length >= 8 || "Min 8 characters",
      emailMatch: () => `The email and password you entered don't match`,
    },
  }),
  methods: {
    async loginMethod() {
      localStorage.setItem("email", this.email);
      this.loader = true;
      try {
        let response = await axios.post(
          config.apiBaseUrl + "/api/Auth/Authenticate",
          {
            username: this.email,
            password: this.password,
          }
        );
        localStorage.setItem("role", response.data.role);
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("isLoggedin", true);
        localStorage.setItem("UserRole", response.data.role);
        this.$store.state.UserRole = response.data.role;
        this.logedinuser = response.data.fullName;
        this.$store.state.user = this.logedinuser;
        axios.defaults.headers.common["Authorization"] =
          "Bearer " + localStorage.getItem("token");
        this.$store.state.isLoggedin = true;

        this.getuser();
        this.getuserInfo();
      } catch (err) {
        this.loader = false;
        this.$toast.error("Username or password incorrect", {
          position: "top-center",
          timeout: 1041,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.79,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: false,
          icon: true,
          rtl: false,
        });
      }
    },
    async getuser() {
      //MAKE FORM DATA
      let formData = new FormData();
      formData.append("systemUuid", 25);

      try {
        await axios
          .post(config.apiBaseUrl + "/api/License/Key", formData, {
            headers: { "Content-Type": "multipart/form-data" },
          })
          .then(() => {
            this.$store.state.isLoggedin = localStorage.getItem("isLoggedin");
            this.loader = false;
            this.$router.push("/Licenses");
          });
      } catch (error) {
        this.loader = false;
        console.log(error.message);
        this.$toast.warning("something went wrong!", {
          position: "top-center",
          timeout: 1041,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.79,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: false,
          icon: true,
          rtl: false,
        });
      }
    },
    getuserInfo() {
      //MAKE FORM DATA
      axios.get(config.apiBaseUrl + "/api/User/Get").then((res) => {
        this.$store.state.userInfo = res.data;
      });
    },
    clearAll() {
      this.localStorage = null;
      this.$store.state.userRoll = null;
      this.logedinuser = null;
      this.$store.state.user = null;
      axios.defaults.headers.common["Authorization"] = null;
      this.$store.state.isLoggedin = false;
    },
  },
  created() {
    this.clearAll();
  },
};
</script>
<style scoped>
* {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  transition: background-color 5000s ease-in-out 0s;
}
.v-progress-circular {
  position: absolute;
  top: calc(50% - 100px);
  left: calc(50% - 25px);
}
.v-dialog {
  position: absolute;
  top: calc(50% - 50px);
  left: calc(50% - 150px);
}
.v-application a {
  margin-left: 0px;
  text-decoration: none;
  text-transform: initial;
  font-weight: 500;
  font-size: 13px;
}
.backgroundColor {
  height: 100vh;
  background-color: #f5f7f8 !important;
}
#LoginTop {
  margin-top: 80px;
}

@media only screen and (max-width: 480px) {
  #LoginTop {
    margin-top: 100px;
  }
}
@media screen and (min-width: 1800px) {
  #LoginTop {
    margin-top: 150px;
  }
}
</style>