<template>
  <div>
    <v-container fluid class="page">
      <v-card elevation="1" class="d-flex flex-column p-4">
        <h1 class="mb-10 text-center">Signup</h1>
        <v-text-field
          name="fullName"
          v-model="fullName"
          outlined
          label="Full Name"
          prepend-inner-icon="mdi-email"
          autocomplete="off"
          hide-details
            color="success darken-2"
          class="mb-4"
        ></v-text-field>
        <v-text-field
          name="email"
          id="email"
          v-model="username"
          outlined
          reaquired
          label="Email Address"
          prepend-inner-icon="mdi-email"
          hide-details
          class="mb-4"
            color="success darken-2"
        ></v-text-field>
        <!-- :rules="[rules.required, rules.email]" -->
        <v-text-field
          id="password"
          name="password"
          label="Password"
          outlined
          v-model="password"
          :append-icon="showpass ? 'mdi-eye' : 'mdi-eye-off'"
          :type="showpass ? 'text' : 'password'"
          prepend-inner-icon="mdi-lock"
          counter
          required
          hide-details
          class="mb-4"
            color="success darken-2"
          @click:append="showpass = !showpass"
        ></v-text-field>
        <v-text-field
          label="Retype Password"
          outlined
          v-model="retypepassword"
          :append-icon="showpass ? 'mdi-eye' : 'mdi-eye-off'"
          :type="showpass ? 'text' : 'password'"
          prepend-inner-icon="mdi-lock"
          counter
          class="mb-4"
          autocomplete="off"
          :rules="[matchingPasswords]"
          @click:append="showpass2 = !showpass2"
            color="success darken-2"
        ></v-text-field>

        <div class="d-flex">
          <v-btn
            class="login-btns ma-0 mt-8 "
            width="23%"
            to="/"
          >
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            class="mt-8 login-btns"
          
            width="75%"
            :disabled="!fullName || !username || !password|| !retypepassword"
            @click="SignupMethod(), (loader = true)"
          >
            Signup
          </v-btn>
        </div>
      </v-card>

      <v-progress-circular
        v-if="loader"
        :size="50"
        :width="3"
        color="purple"
        indeterminate
      ></v-progress-circular>
    </v-container>
  </div>
</template>
<script>
import axios from "axios";
import config from "@/Configuration/config";
export default {
  name: "SignupPage",
  data() {
    return {
      showpass: false,
      showpass2: false,
      loader: false,
      fullName: null,
      username: null,
      password: null,
      retypepassword: null,
      AccountVerified: false,
    };
  },
  methods: {
    matchingPasswords: function () {
      if (this.password === this.retypepassword) {
        return true;
      } else {
        return "Passwords does not match.";
      }
    },
    SignupMethod() {
      this.loader = false;
      axios
        .post(config.apiBaseUrl + "/api/Auth/Signup", {
          fullName: this.fullName,
          username: this.username,
          password: this.password,
          accountVerified: this.accountVerified,
        })
        .then((res) => {
          this.loader = false;
          if (res.data.isSuccessful == true) {
            this.$toast.success("Signed up successfully", {
              position: "top-center",
              timeout: 2000,
              closeOnClick: true,
              pauseOnFocusLoss: true,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 0.79,
              showCloseButtonOnHover: false,
              hideProgressBar: true,
              closeButton: false,
              icon: true,
              rtl: false,
            });
             this.$router.push("/");
          }
          else if(res.data.msg == 'please enter a valid email') {
             this.$toast.error("please enter a valid email!", {
              position: "top-center",
              timeout: 2000,
              closeOnClick: true,
              pauseOnFocusLoss: true,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 0.79,
              showCloseButtonOnHover: false,
              hideProgressBar: true,
              closeButton: false,
              icon: true,
              rtl: false,
            });
          }
          else if(res.data.msg == 'user already exists!') {
             this.$toast.error("User already exists!", {
              position: "top-center",
              timeout: 2000,
              closeOnClick: true,
              pauseOnFocusLoss: true,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 0.79,
              showCloseButtonOnHover: false,
              hideProgressBar: true,
              closeButton: false,
              icon: true,
              rtl: false,
            });
          }

         
        })
        .catch(() => {
          this.loader = false;
          this.$toast.error("something went wrong!", {
            position: "top-center",
            timeout: 1041,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.79,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: false,
            icon: true,
            rtl: false,
          });
        });
    },
  },
};
</script>
<style scoped>
.v-card {
  position: absolute;
  padding: 20px;
  width: 500px;
  padding-top: 30px;
  padding-bottom: 25px;
  top: calc(50% - 270px);
  left: calc(50% - 250px);
}

/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: none;
  -webkit-background-clip: text;
  transition: background-color 5000s ease-in-out 0s;
}
.v-progress-circular {
  position: absolute;
  top: calc(50% - 100px);
  left: calc(50% - 25px);
}

.v-application a {
  margin-left: 0px;
  text-decoration: none;
  text-transform: lowercase;
  font-weight: 500;
  font-size: 13px;
}

</style>
