<template>
  <div>
    <v-app-bar color="white" elevation="1" app clipped-left height="60">
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <div class="d-flex align-center">
        <img
          src="../assets/mobivone-mv.svg"
          width="50"
          alt="Logo"
          class="shrink mr-2"
          contain
          transition="scale-transition"
        />
      </div>
        <h1 class="headline">MOBIVONE</h1>
      <router-link to="/Licenses" class="custom-link">
     Licenses
      </router-link>
      <v-spacer></v-spacer>
      <v-card class="mr-5" elevation="0">
        <span v-if="User != null">{{
          User.fullName
        }}</span>
        <span v-else>User</span>
      </v-card>

      <v-icon>mdi-account </v-icon>

      <v-menu offset-y transition="slide-y-reverse-transition">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            fab
            dark
            small
            color="white"
            elevation="0"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon color="black"> mdi-chevron-down </v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item-group rounded-l-0>
            <v-list-item dense class="ma-15 pa-12" @click="editDialog = true">
              <v-avatar size="25" class="me-4">
                <v-icon> mdi-account </v-icon>
              </v-avatar>
              <v-list-item-title class="pr-6">Profile</v-list-item-title>
            </v-list-item>
            <v-list-item dense link @click="logout()">
              <v-avatar dark size="25" class="me-4">
                <v-icon> mdi-logout</v-icon>
              </v-avatar>
              <v-list-item-title>Logout</v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>
    </v-app-bar>
    <v-dialog v-model="editDialog" persistent max-width="500" class="pa-12">
      <v-card>
        <v-toolbar
          block
          color=""
          class="grey lighten-4 clack--text px-1"
          dense
          elevation="0"
        >
        <span class="title" >Profile</span>
        </v-toolbar>

        <v-container>
          <v-row dense class="pa-0">
            <v-col dense cols="12" sm="6" md="12">
              <v-row>
                <v-col dense cols="12" sm="6" md="6">
                  <v-text-field v-if="User != null"
                    v-model="User.fullName"
                    label="Full Name"
                    dense
                    outlined
                    flat
                    tile
                    disabled
                  ></v-text-field>
                </v-col>
                <v-col dense cols="12" sm="6" md="6">
                  <v-text-field v-if="User != null"
                    v-model="User.referralId"
                    label="Referral Id"
                    dense
                    outlined
                    flat
                    disabled
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-text-field v-if="User != null"
                v-model="User.email"
                label="Email"
                dense
                outlined
                flat
              ></v-text-field
              ><v-text-field v-if="User != null"
                v-model="User.phone"
                label="Phone"
                dense
                outlined
                flat
              ></v-text-field>
              <v-text-field v-if="User != null"
                v-model="User.password"
                label="Password"
                dense
                outlined
                flat
              ></v-text-field>
              <v-card-actions>
                <v-spacer></v-spacer>

                <v-toolbar-item class="mr-4">
                  <v-btn
                    dark
                    class="grey lighten-4 toolbarbutton black--text"
                    tile
                    text
                    @click="editDialog = false"
                  >
                    Cancel
                  </v-btn>
                </v-toolbar-item>

                <v-toolbar-item class="mr-n2">
                  <v-btn
                    dark
                    class="grey darken-2 toolbarbutton"
                    tile
                    text
                    @click="updateUserProfile(), (editDialog = false)"
                  >
                    Save
                  </v-btn>
                </v-toolbar-item>
              </v-card-actions>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import axios from "axios";
import config from "@/Configuration/config";
export default {
  data() {
    return {
      editDialog: false,
      User: null,
      drawer: true,
      dialog: false,
    };
  },
  methods: {
    updateUserProfile() {
      axios
        .post(config.apiBaseUrl + "/api/User/UpdateProfile", {
          email: this.$store.state.userInfo.email,
          phone: this.$store.state.userInfo.phone,
          password: this.$store.state.userInfo.password,
        })
        .then(() => {
          this.getuser();
        });
    },

    logout() {
      axios.get(config.apiBaseUrl + "/api/User/Logout").then(() => {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        localStorage.removeItem("userId");
        (axios.defaults.headers.common["Authorization"] = null),
          (this.$store.state.isLoggedin = false);
        localStorage.setItem("isLoggedin", false);
        this.$router.push("/");
      });
    },
    getuser(){
      axios.get(config.apiBaseUrl + "/api/User/Get").then((res) => {
        this.User = res.data;
      });
    }
  },
  created(){
    this.getuser();
  }
};
</script>

<style scoped>
.header {
  width: 100%;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
.active-icon i.v-icon.v-icon {
  color: #2a579a !important;
}
.active-icon span {
  color: #2a579a !important;
}
.main-color {
  background-color: #2a579a !important;
  color: #2a579a !important;
}

.v-sheet {
  padding: 0 !important;
  height: none !important;
}
.v-list-item {
  margin: 0 !important;
  padding: 0 5px 0 5px !important;
}
.licenseSelection {
  border-bottom: 2px solid #b8482a !important;
}
.custom-link{
  color: black;
  font-size: 18px !important;
  text-decoration: none;
}
.router-link-active {
  border-bottom: 3px solid orange;
}

</style>
