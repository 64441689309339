<template>
  <div>
    <v-container fluid class="pa-5">
      <v-row class="">
        <v-col cols="12" md="4">
          <h3 class="name">Licenses</h3>
        </v-col>
        <v-col cols="12" md="8">
          <div class="d-md-flex">
            <v-select
              :items="products"
              v-model="skoolzeDropdwon"
              outlined
              dense
              hide-details
              class="me-2 header-status custom-select mb-1 mb-md-0"
              @change="GetAll()"
              color="indigo darken-1"
              :menu-props="{ bottom: true, offsetY: true }"
            ></v-select>
            <v-select
              :items="producttype2"
              v-model="skoolzeVersion"
              outlined
              dense
              hide-details
              class="me-2 header-status custom-select mb-1 mb-md-0"
              @change="GetAll()"
              color="indigo darken-1"
              :menu-props="{ bottom: true, offsetY: true }"
            ></v-select>
            <v-text-field
              class="mb-1 mb-md-0"
              append-icon="mdi-magnify"
              type="text"
              name="search"
              label="Search"
              outlined
              dense
              clearable
              hide-details
              color="#2A579A"
              v-model="filteredText"
              @click:clear="(filteredText = ''), GetAll()"
              @click:append="GetAll()"
            >
            </v-text-field>
            <v-btn
              class="ms-1 primary darken-2 text-capitalize"
              depressed
              height="40"
              @click="AddDialog = !AddDialog"
              ><v-icon>mdi-plus</v-icon></v-btn
            >
          </div>
          <!--          <v-row>-->
          <!--            <v-col cols="12" sm="6" md="3" class="pa-0">-->
          <!--              -->
          <!--            </v-col>-->
          <!--            <v-col cols="12" sm="6" md="3" class="pa-0">-->
          <!--              -->
          <!--            </v-col>-->
          <!--            <v-col cols="12" sm="6" md="6" class="pa-0">-->
          <!--             -->
          <!--            </v-col>-->
          <!--          </v-row>-->
        </v-col>
      </v-row>
      <v-divider class="mb-3 mt-1"></v-divider>
      <!-- <div class="line"></div> -->
      <template>
        <v-data-table
          v-if="LicenseData == null"
          :headers="headers"
          class="grey--text elevation-0"
          hide-default-footer
        ></v-data-table>
        <v-data-table
          v-else
          :headers="headers"
          :items="LicenseData"
          class="black--text elevation-0 row-height-50 licenseTable"
          hide-default-footer
          disable-pagination
          id="tableFontSize"
        >
          <template v-slot:[`item.sno`]="{ index }">
            {{ (index += 1) }}
          </template>
          <template v-slot:[`item.machineCode`]="{ item }">
            <span v-if="item.business" id="SystemUuidFont">
               {{ item.business }} <br />
               <span id="SystemUuidFont2">{{ item.license.machineCode }} </span>
            </span>
          </template>
          <template v-slot:[`item.license.expires`]="{ item }">
            <span v-if="item.license.expires">{{
              formatedDate(item.license.expires)
            }}</span>
            <span v-else>Expiry Date</span>
          </template>

          <template v-slot:[`item.fullName`]="{ item }">
            <span v-if="item.fullName" class="tableRowFontSize">
              {{ item.fullName }} <br />
              {{ item.address }}
            </span>
          </template>
          <template v-slot:[`item.email`]="{ item }">
            <span v-if="item.email" class="tableRowFontSize">
              {{ item.email }} <br />
              {{ item.phone }}
            </span>
          </template>
          <!-- <template v-slot:[`item.expires`]="{ item }">
            {{ item }}
          </template> -->
          <template v-slot:[`item.isActive`]="{ item }">
            <v-chip
              x-small
              v-if="item.license.trialActivation"
              dark
              color="grey darken-2"
              label
            >
              Trial
            </v-chip>
            <span v-else
              ><v-chip color="success" x-small label> Pro </v-chip>
            </span>
            |

            <span v-if="item.license.licenseType">
              <v-chip v-if="item.license.licenseType==10" dark color="grey darken-2" x-small label>
                Desktop
              </v-chip>
              <v-chip v-if="item.license.licenseType==20" dark color="grey darken-2" x-small label>
                Tenant
              </v-chip>
              <v-chip v-if="item.license.licenseType==30" dark color="grey darken-2" x-small label>
                Cloud
              </v-chip>
            </span>
            <!-- <span v-switch="item.license.licenseType">

              <v-chip v-case="10" dark color="grey darken-2" x-small label>
                Desktop
              </v-chip>
              <v-chip v-case="20" dark color="grey darken-2" x-small label>
                Tenant
              </v-chip>
              <v-chip v-case="30" dark color="grey darken-2" x-small label>
                Cloud
              </v-chip>
            </span> -->

            <!-- <span v-if="item.license.licenseType == 10 || item.license.licenseType == 20">
              <span v-if="item.license.licenseType == 10"> Desktop </span>
              <span v-else>Tenant </span>
            </span>
            <span v-else>Cloud</span> -->

            <!-- <v-chip
              dark
              v-if="item.isActive"
              color="success "
              class="shortsize"
              x-small
              label
            >
              Active
            </v-chip>
            <v-chip
              dark
              v-else
              color="grey darken-2"
              class="shortsize"
              x-small
              label
            >
              Inactive
            </v-chip> -->
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  small
                  dense
                  color="primary"
                  @click="takeData(item), (editDialog = true)"
                  class="ms-2"
                  v-bind="attrs"
                  v-on="on"
                  >mdi-square-edit-outline
                </v-icon>
              </template>
              <span>Update</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <!-- <template></template> -->
                <v-icon
                  v-if="item.isActive"
                  dense
                  small
                  color="success"
                  @click="takeData(item), (revokDialog = true)"
                  class="ms-2"
                  v-bind="attrs"
                  v-on="on"
                  >mdi-checkbox-marked
                </v-icon>
                <v-icon
                  v-else
                  dense
                  small
                  color="grey darken-2"
                  @click="takeData(item), (revokDialog = true)"
                  class="ms-2"
                  v-bind="attrs"
                  v-on="on"
                  >mdi-checkbox-marked
                </v-icon>
              </template>
              <span v-if="item.isActive">deactivate</span>
              <span v-else>activate</span>
            </v-tooltip>
            <v-menu offset-y offset-x>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  dark
                  v-bind="attrs"
                  v-on="on"
                  icon
                  small
                  class="text-capitalize"
                >
                  <v-icon small>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list dense>
                <v-list-item
                  link
                  @click="takeData(item), (activateDialog = true)"
                >
                  <div class="d-flex">
                    <v-icon dense small color="success " class="me-2"
                      >mdi-lock-open-outline
                    </v-icon>
                    <span class="caption">Activate</span>
                  </div>
                </v-list-item>

                <v-list-item link @click="DownloadKey(item)">
                  <div class="d-flex">
                    <v-icon dense small color="info" class="me-2"
                      >mdi-key-star
                    </v-icon>
                    <span class="caption">License</span>
                  </div>
                </v-list-item>

                <v-list-item v-if="userProfile==100" link @click="takeData(item), (deletDialog = true)">
                  <div class="d-flex">
                    <v-icon dense small color="red" class="me-2"
                      >mdi-trash-can-outline
                    </v-icon>
                    <span class="caption">Delete</span>
                  </div>
                </v-list-item>
                
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
        <v-row class="no-gutters mt-2" align="center">
          <v-spacer style="width: 75%"></v-spacer>

          <span class="pagination-text">Rows per page</span>
          <v-select
            :menu-props="{ bottom: true, offsetY: true }"
            :items="options"
            v-model="selectedPageSize"
            dense
            class="ma-2"
            style="width: 50px"
            @change="GetAll"
          ></v-select>

          <span
            v-if="pagination != null"
            class="mr-4 grey--text pagination-text"
          >
            Page {{ pagination.page }} of {{ pagination.totalPages }}
          </span>

          <v-icon
            small
            dark
            color="success "
            class="mr-1"
            @click="backwardButtonCall"
            >mdi-chevron-left</v-icon
          >

          <v-icon
            small
            dark
            color="success "
            @click="forwardButtonCall()"
            class="ml-1"
            >mdi-chevron-right</v-icon
          >
        </v-row>

        <!-- DiaLogs Section -->

        <!-- edit Dialog -->

        <v-dialog v-model="editDialog" persistent max-width="500" class="pa-12">
          <v-card>
            <v-toolbar block class="grey lighten-4" dense elevation="0">
              <span class="subtitle-1">
                {{ licenseObj.machineCode }}
              </span>
            </v-toolbar>

            <v-container>
              <v-row dense class="pa-0">
                <v-col dense cols="12" sm="6" md="12">
                  <v-text-field
                    v-model="currentUser.fullName"
                    label="Full Name"
                    dense
                    outlined
                    flat
                    tile
                  ></v-text-field>
                  <v-text-field
                    label="phone"
                    v-model="currentUser.phone"
                    dense
                    outlined
                    flat
                    tile
                  ></v-text-field>
                  <v-text-field
                    v-model="currentUser.email"
                    label="Email"
                    dense
                    outlined
                    flat
                    tile
                  ></v-text-field>
                  <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn
                      dark
                      class="grey lighten-4 black--text toolbarbutton text-capitalize"
                      tile
                      text
                      @click="editDialog = false"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      dark
                      class="grey darken-2 white--text toolbarbutton text-capitalize"
                      tile
                      text
                      @click="simpleupdateUser(), (editDialog = false)"
                    >
                      Save
                    </v-btn>
                  </v-card-actions>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-dialog>

        <!-- Delete Dialog -->
        <v-dialog v-model="deletDialog" persistent max-width="400">
          <v-card>
            <v-toolbar elevation="0" class="grey lighten-4 black--text subtitle-1">
              Delete License
            </v-toolbar>
            <v-card-text class="mt-4"
              >Are You sure? You want to delete
              <span class="red--text darken-1 font-weight-bold">
                {{ currentUser.fullName }}</span
              >
              <b class="error--text text--darken-2">{{
                currentUser.title
              }}</b></v-card-text
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                tile
                class="grey lighten-4 black--text toolbarbutton text-capitalize"
                text
                @click="deletDialog = false"
              >
                Cancel
              </v-btn>
              <v-btn
                depressed
                tile
                dark
                class="red lighten-2 text-capitalize"
                @click="deleteUser(), (deletDialog = false)"
              >
                Delete
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="revokDialog" persistent max-width="400">
          <v-card>
            <v-toolbar elevation="0" class="subtitle-1 grey lighten-4">
              <span v-if="currentUser.isActive"> Deactivate </span>
              <span v-else> Activate </span> <span> &nbsp;License </span>
            </v-toolbar>
            <v-card-text class="mt-4"
              >Are You sure? You want to Revoke
              <span class="green--text darken-1 font-weight-bold">
                {{ currentUser.fullName }}</span
              >
              <b class="error--text text--darken-2">{{
                currentUser.title
              }}</b></v-card-text
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                class="black--text grey lighten-4 text-capitalize"
                text
                tile
                @click="revokDialog = false"
              >
                Cancel
              </v-btn>
              <v-btn
                tile
                dark
                class="grey darken-2 text-capitalize"
                @click="revokeUser(), (revokDialog = false)"
              >
                <span v-if="currentUser.isActive"> Deactivate </span>
                <span v-else> Activate </span>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>




        <!--Add Dialog-->
        <v-dialog v-model="AddDialog" persistent max-width="500" >
          <v-card class="pa-2">
            <v-toolbar block class="grey lighten-4" dense elevation="0"
              >Add Client
            </v-toolbar>
            <v-card-text class="mt-2">
              <v-row>
                <v-col cols="12" >
                  <v-text-field
                    v-model="ActivationModel.fullName"
                    outlined
                    dense
                    label="Full Name"
                    hide-details
                    class="my-2"
                  ></v-text-field>
                
                  <v-text-field
                    v-model="ActivationModel.email"
                    outlined
                    dense
                    label="Email"
                    hide-details
                    class="my-2"
                  ></v-text-field>
         
                  <v-text-field
                    v-model="ActivationModel.phone"
                    outlined
                    dense
                    label="Phone"
                    hide-details
                    class="my-2"
                  ></v-text-field>
             
                  <v-text-field
                    v-model="ActivationModel.address"
                    outlined
                    class="my-2"
                    dense
                    label="Address"
                    hide-details
                  ></v-text-field>
            
                  <v-text-field
                    v-model="ActivationModel.country"
                    outlined
                    class="my-2"
                    dense
                    label="Country"
                    hide-details
                  ></v-text-field>
         
                  <v-text-field
                    v-model="ActivationModel.business"
                    outlined
                    class="my-2"
                    dense
                    label="Business"
                    hide-details
                  ></v-text-field>
                <!-- </v-col>
                <v-col cols="12" > -->
                  <v-select
                    outlined
                    dense
                    class="my-2"
                    :menu-props="{ bottom: true, offsetY: true }"
                    :items="products"
                    item-text="text"
                    v-model="ActivationModel.product"
                    item-value="value"
                    hide-details
                  ></v-select>
              
                  <v-text-field
                    v-model="ActivationModel.referralId"
                    outlined
                    dense
                    label="Referral Id"
                    hide-details
                    class="my-2"
                  ></v-text-field>
       
                  <v-text-field
                    v-model="ActivationModel.machineCode"
                    outlined
                    class="my-2"
                    dense
                    label="Machine Code"
                    hide-details
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions class="my-2">
              <v-row class="pe-4">
                <v-spacer></v-spacer>
                <v-btn
                  depressed
                  class="ms-2 text-capitalize"
                  @click="AddDialog = !AddDialog"
                  >Cancel</v-btn
                >
                <v-btn
                  depressed
                  class="ms-2 primary darken-3 text-capitalize"
                  width="90"
                  @click="AddClient(), (AddDialog = !AddDialog)"
                  :disabled="!ActivationModel.machineCode"
                  >Trial</v-btn
                >
              </v-row>
            </v-card-actions>
          </v-card>
        </v-dialog>



<!-- ActivateLicense Dialog -->

        <v-dialog
          v-model="activateDialog"
          persistent
          max-width="900"
          v-if="currentUser !== null"
        >
          <v-card class="">
            <v-toolbar block class=" grey lighten-4" dense elevation="0">
              <span class="subtitle-1"> {{ licenseObj.machineCode }} </span>
            </v-toolbar>

            <v-container class="pa-5" dense>
              <v-row dense class="">
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    hide-details
                    dense
                    disabled
                    outlined
                    elevation="0"
                    label="Full Name"
                    v-model="currentUser.fullName"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    hide-details
                    dense
                    disabled
                    outlined
                    elevation="0"
                    label="Email"
                    v-model="currentUser.email"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="4" md="12">
                  <v-row dense class="pa-0">
                    <v-col cols="12" sm="4">
                      <v-select
                        :menu-props="{ bottom: true, offsetY: true }"
                        label="License"
                        :items="producttype"
                        v-model="licenseObj.trialActivation"
                        item-text="text"
                        item-value="value"
                        outlined
                        dense
                        hide-details
                        class="header-status"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" sm="4">
                      <v-select
                        outlined
                        dense
                        :menu-props="{ bottom: true, offsetY: true }"
                        :items="LicenseTypes"
                        item-text="text"
                        v-model="licenseObj.licenseType"
                        item-value="value"
                        hide-details
                      ></v-select>
                    </v-col>
                    <v-col cols="12" sm="4">
                      <v-menu
                        ref="menu1"
                        v-model="menu1"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            outlined
                            dense
                            :value="formatedDate(currentUser.expiry)"
                            label="Expiry Date"
                            prepend-inner-icon="mdi-calendar"
                            v-bind="attrs"
                           
                            v-on="on"
                          ></v-text-field>
                        </template>

                        <v-date-picker
                          outlined
                          :value="formatedDate(currentUser.expiry)"
                          v-model="currentUser.expiry"
                          @input="menu1 = false"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="12" sm="6" md="12" class="mt-n6">
                      <v-text-field
                        label="webDbServer"
                        v-model="currentUser.webDbServer"
                        outlined
                        dense
                        hide-details
                        class="header-status"
                        tile
                      ></v-text-field>
                    </v-col>
                    <v-spacer></v-spacer>

                    <v-col cols="12" sm="6" md="12" class="text-end mt-3">
                      <v-card-actions class="pa-0">
                        <v-spacer></v-spacer>
                        <v-btn
                          dark
                          class="grey darken-2 toolbarbutton text-capitalize"
                          tile
                          text
                          @click="activateDialog = false"
                        >
                          Cancel
                        </v-btn>

                        <v-btn
                          dark
                          class="grey darken-2 toolbarbutton text-capitalize"
                          tile
                          text
                          @click="activateLicense(), (activateDialog = false)"
                        >
                          Activate
                        </v-btn>
                      </v-card-actions>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-dialog>
      </template>
      <v-progress-circular
        v-if="LicenseData == null"
        :size="40"
        :width="3"
        color="black"
        indeterminate
      ></v-progress-circular>
    </v-container>
  </div>
</template>
<script>
import config from "../Configuration/config";
import axios from "axios";
import moment from "moment";
import Vue from "vue";
import VSwitch from "v-switch-case";

Vue.use(VSwitch);
export default {
  name: "CampaignComp",
  data() {
    return {
      userProfile:0,
      currentLicense: null,
      filterTag: "",
      loggedInUser: localStorage.getItem("role"),
      switch1: false,
      updatename: null,
      AddDialog: false,
      menu1: false,
      menu2: false,
      defaultstatus: 50,
      defaultproduct: 25,
      skoolzeDropdwon: 100,
      skoolzeVersion: "all",
      defaultproducttype: 10,
      activateproducttype: 10,
      deletDialog: false,
      editDialog: false,
      activateDialog: false,
      revokDialog: false,
      LicenseData: null,
      asinLink: "",
      currentUser: [],
      licenseObj: [],
      Keywords: [],
      headers: [
        {
          class: "grey--text  font-weight-medium",
          text: "#",
          sortable: false,
          value: "sno",
          width: "10px",
        },
        {
          class: "grey--text  font-weight-medium",
          text: "Machine Code",
          align: "start",
          sortable: false,
          value: "machineCode",
        },

        {
          class: "grey--text  font-weight-medium",
          text: "Client",
          align: "start",
          sortable: false,
          value: "fullName",
        },

        {
          class: "grey--text  font-weight-medium",
          text: "Email / Phone",
          value: "email",
        },
        {
          class: "grey--text  font-weight-medium",
          text: "Expiry",
          value: "license.expires",
        },
        {
          class: "grey--text  font-weight-medium",
          text: "Status",
          value: "isActive",
          align: "center",
        },
        {
          class: "grey--text  font-weight-medium",
          text: "Actions",
          value: "actions",
          align: "center",
          sortable: false,
        },
      ],
      page: 1,
      options: [
        { text: "10", value: 10 },
        { text: "25", value: 25 },
        { text: "50", value: 50 },
        { text: "100", value: 100 },
      ],
      products: [
        { text: "Skoolze", value: 100 },
        { text: "Robo", value: 200 },
      ],
      statustype: [
        { text: "Active", value: true },
        { text: "Inactive", value: false },
      ],
      producttype: [
        { text: "Trial", value: true },
        { text: "Pro", value: false },
      ],
      LicenseTypes: [
        { text: "Desktop", value: 10 },
        { text: "Tenant", value: 20 },
        { text: "Cloud", value: 30 },
      ],
      producttype2: [
        { text: "All", value: "all" },
        { text: "Trial", value: "trial" },
        { text: "Pro", value: "pro" },
      ],
      selectedPageSize: 25,
      pagination: null,
      pageNumOnSearch: "",
      filteredText: "",
      totalRecords: "",
      userId: localStorage.getItem("userId"),
      ActivationModel: {
        product: 100,
        machineCode: "",
        referralId: "",
        fullName: "",
        email: "",
        phone: "",
        business: "",
        address: "",
        country: "",
      },

      NewActivationModel: {
        id: null,
        productId: "",
        product: "",
        Array: [],
        machineCode: "",
        referralId: "",
        fullName: "",
        email: "",
        phone: "",
        business: "",
        address: "",
        country: "",
      },
      activateLices: {
        id: 0,
        product: 100,
        machineCode: "",
        referralId: "",
        fullName: "",
        email: "",
        phone: "",
        business: "",
        address: "",
        country: "",
      },
      License: {
        id: 0,
        systemUuid: "",
        product: 100,
        type: 10,
        isDesktop: false,
        isWeb: false,
        fullName: "",
        email: "",
        phone: "",
        business: "",
        address: "",
        country: "",
        signupDate: new Date().toJSON().substring(0, 10),
        issuanceDate: new Date().toJSON().substring(0, 10),
        expiryDate: new Date().toJSON().substring(0, 10),
        connectionString: "",
        key: "",
        allowedSystems: 0,
        isActive: false,
      },
      birthDate: "",
      date: new Date(),
      currentDate: new Date().toISOString(0, 10),
    };
  },

  methods: {

    // resetEdit() {
    //   this.CurrentUser = {
    //     userId: 0,
    //     username: "",
    //     password: "",
    //     fullName: "",
    //     role: 100,
    //   };
    // },
    formatedDate(value) {
      return moment(value).format("LL");
    },
    DownloadKey(license) {
      const licenseCode = license.key;
      const blob = new Blob([licenseCode], { type: "text/plain" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = "license.lic";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    async AddClient() {
      try {
        let response = await axios({
          method: "Post",
          url: config.apiBaseUrl + "/api/License/Trial",
          data: this.ActivationModel,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        });
        
        console.error(response)
        this.GetAll();
      } catch (e) {
        console.log(e.message);
      }
    },
    updateLicense() {
      this.LicenseData = null;
      axios
        .post(config.apiBaseUrl + "/api/License/Update", {
          id: this.currentUser.id,
          systemUuid: "string",
          product: 100,
          type: true,
          isDesktop: true,
          isWeb: true,
          fullName: this.currentUser.fullName,
          email: this.currentUser.email,
          phone: this.currentUser.phone,
          business: "string",
          address: "string",
          country: "string",
          signupDate: "2023-01-20T23:09:21.681Z",
          issuanceDate: "2023-01-20T23:09:21.681Z",
          expiryDate: "2023-01-20T23:09:21.681Z",
          connectionString: "string",
          key: "string",
          allowedSystems: 0,
          isActive: true,
        })
        .then((res) => {
          this.LicenseData = res.data;
          this.GetAll();
        });
    },
    simpleupdateUser() {
       // {
      //     id: this.currentUser.id,
      //     type:  this.licenseObj.licenseType,
      //     expires:this.currentUser.expiry,
      //     webDbServer:this.currentUser.webDbServer,
      //     trialActivation:this.licenseObj.trialActivation
      //   }
      // console.log(this.currentUser)
      this.LicenseData = null;
      axios
        .post(config.apiBaseUrl + "/api/License/Update", this.currentUser)
        .then((res) => {
          this.LicenseData = res.data;
          this.GetAll();
        });
    },

    activateLicense() {
      if (this.licenseObj.trialActivation == null)
        this.licenseObj.trialActivation = false;
      axios
        .post(config.apiBaseUrl + "/api/License/Activate", {
          id: this.currentUser.id,
          type: this.licenseObj.licenseType,
          expires: this.currentUser.expiry,
          webDbServer: this.currentUser.webDbServer,
          trialActivation: this.licenseObj.trialActivation,
        })
        .then((res) => {
          this.LicenseData = res.data;
          this.GetAll();
        })
        .catch((error) => {
          if (error.config.headers.Authorization == "Bearer null") {
            this.$router.push("/404");
          }
        });
    },

    takeData(item) {
      this.currentUser = item;
      this.currentLicense = item.license;
      this.licenseObj = this.currentUser.license;

    },
    GetAll() {
      this.LicenseData = null;
      axios
        .post(config.apiBaseUrl + "/api/License/GetAll", {
          pageSize: this.selectedPageSize,
          page: this.page,
          filterText: this.filteredText,
          filterTag: this.skoolzeVersion,
          isActive: true,
          product: this.skoolzeDropdwon,
        })

        .then((response) => {
          this.LicenseData = response.data.data;
          this.pagination = response.data.pagination;
        })
        .catch((error) => {
          if (error.config.headers.Authorization == "Bearer null") {
            this.$router.push("/404");
          }
        });
    },
    copyText() {
      navigator.clipboard.writeText(this.currentUser.fullName);
    },
    forwardButtonCall() {
      if (this.page < this.pagination.totalPages) {
        this.page++;
        this.GetAll();
      } else {
        return;
      }
    },
    backwardButtonCall() {
      if (this.page > 1) {
        this.page--;
        this.GetAll();
      } else {
        return;
      }
    },

    revokeUser() {
      this.LicenseData = null;
      const fd = new FormData();
      fd.append("id", this.currentUser.id);
      this.GetAll();
      axios({
        method: "post",
        url: config.apiBaseUrl + "/api/License/Revoke",
        data: fd,
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "applicatio/json",
        },
      })
        .then(() => {
          this.GetAll();
        })
        .catch((error) => {
          if (error.config.headers.Authorization == "Bearer null") {
            this.$router.push("/404");
          }
        });
    },
    deleteUser() {
      this.LicenseData = null;
      const fd = new FormData();
      fd.append("id", this.currentUser.id);
      axios({
        method: "post",
        url: config.apiBaseUrl + "/api/License/Remove",
        data: fd,
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "applicatio/json",
        },
      })
        .then(this.GetAll)
        .catch((error) => {
          if (error.config.headers.Authorization == "Bearer null") {
            this.$router.push("/404");
          }
        });
    },
    getuserInfo() {
      // console.log(this.$store.state.userRole)
      // console.log(localStorage.getItem("UserRole"))
      this.userProfile = localStorage.getItem("UserRole")
  },
},
  created() {
    
    this.getuserInfo();
    this.GetAll();
  },
};
</script>
<style scoped>
.asin-link {
  text-decoration: underline;
  font-size: 14px;
}

.pagination-text {
  font-size: 12px !important;
}

.menu-page {
  text-transform: inherit !important;
}

.alignswitch {
  display: flex;
  justify-content: end !important;
}

.shortsize {
  justify-content: center;
  width: 57px;
}

.toolbarbutton {
  width: 100px !important;
}

#SystemUuidFont {
  font-size: 12px !important;
}
#SystemUuidFont2 {
  font-size: 11px !important;
}

.tableRowFontSize {
  font-size: 13px !important;
}
</style>
